<template>
  <div class="flex items-center">
    <span :class="getClass" />
    <span class="pl-1">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SmsBlockStatus',
  props: {
    isBlocked: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      default: '--',
    },
  },
  computed: {
    getClass() {
      if (this.isBlocked) {
        return `status-red`
      } else {
        return `status-green`
      }
    },
  },
}
</script>

<style scoped lang="scss">
.status-green {
  @apply text-sm w-2.5 h-2.5 rounded-full bg-oGreen;
}
.status-red {
  @apply text-sm w-2.5 h-2.5 rounded-full bg-oRed animate-pulse;
}
</style>
